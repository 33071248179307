import Vue from "vue";
import Vuex from "vuex";

import { alert } from "@/store/modules/alert.module";
import { authentication } from "@/store/modules/auth.module";
import { node } from "@/store/modules/node.module";
import { project } from "@/store/modules/project.module";
import { processes } from "@/store/modules/processes.module";
import { dictionary } from "@/store/modules/dictionary.module";
import { app } from "@/store/modules/app.module"
import { multilang } from "@/store/modules/multilang.module";
import {chatgpt} from "@/store/modules/chatgpt.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    node,
    project,
    processes,
    dictionary,
    app,
    multilang,
    chatgpt
  },
});
