import { defaultApiInstance } from "@/api";

export const processesService =  {
  getProcesses,
  getProcess,
  saveProcess,
  updateNameProcess,
  addProcess,
  deleteProcess
}

async function getProcesses() {
  return await defaultApiInstance.get('/processes');
}

async function getProcess(id) {
  return await defaultApiInstance.get(`/processes/${id}`);
}

async function deleteProcess(id) {
  return await defaultApiInstance.delete(`/processes/${id}`);
}

async function saveProcess(process, id) {
  // availableNodes is useless (50/50)
  return await defaultApiInstance.patch(`processes/${id}?new_process_format=true`, { process_n8n: { ...process, availableNodes: {} } })
}

async function updateNameProcess(name, data, id) {
  return await defaultApiInstance.patch(`processes/${id}?new_process_format=true`, {process_name: name, process_n8n: data})
}

async function addProcess(process_settings) {
  return await defaultApiInstance.post(`processes`, process_settings);
}