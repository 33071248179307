import {processesService} from "@/services/processes.service";
import Vue from "vue";
import {DEFAULT_CANVAS_THEME} from "@/constants/canvas";

export const processes = {
  namespaced: true,
  state: {
    processes: [],
    process: {},
    showNav: false,
    isEditNav: false,
    reloadProcesses: false,
    newProcess: null,
    outsideProcessId: null,
    editorData: {
      nodes: {},
      connections: {},
      options: {
        /**
         * Размер сетки
         */
        cellSize: 50,
        /**
         * Включает/отключает привязки
         */
        gridBinding: true,
        canvasTheme: DEFAULT_CANVAS_THEME,
      },
      availableNodes: {},
      enterNode: {},
    },
    localContext: null,
    groupedContext: null,
    outGroupContext: null,
    parentNodeId: null,
  },
  getters: {
    processes: state => state.processes,
    process: state => state.process,
    idOfProcess: state => state.process?.id,
    nameOfProcess: state => state.process?.process_name,
    processN8N: state => state.process?.process_n8n,
    showNav: state => state.showNav,
    outsideProcessId: state => state.outsideProcessId,
    newProcess: state => state.newProcess,
    isEditNav: state => state.isEditNav,
    reloadProcesses: state => state.reloadProcesses,
    editorData: state => state.editorData,
    localContext: state => state.localContext,
    groupedContext: state => state.groupedContext,
    outGroupContext: state => state.outGroupContext,
    parentNodeId: state => state.parentNodeId,
    canvasTheme: state => state.editorData.options.canvasTheme,
    enterNode: state => state.editorData.enterNode
  },
  mutations: {
    getProcesses (state, processes) {
      state.processes = processes;
    },
    getProcess (state, process) {
      state.process = process;
    },
    changeShowNav (state, value) {
      state.showNav = value
    },
    setOutsideProcessId (state, value) {
      state.outsideProcessId = value;
    },
    setNewProcess (state, value) {
      state.newProcess = value;
    },
    changeIsEditNav (state, value) {
      state.isEditNav = value
    },
    changeReloadProcesses (state, value) {
      state.reloadProcesses = value
    },
    setEditorData(state, editor_data) {
      state.editorData = editor_data;
    },
    changeOutputName(state, {outputName, index, node}){
      Vue.set(node, `${index}`, outputName)
      processesService.saveProcess(state.editorData, state.process.id)
    },
    setLocalContext(state, data){
      Vue.set(state, 'localContext', data)
    },
    setConnectionsNodes(state, {data, option}) {
    
      let context = null
      let condition = null
      let upperContext = state.localContext ?? state.editorData
      
      if(data.firstInGroup) {
        context = upperContext.nodes[data.firstInGroup].grouped
        condition = context.connections[data.groupInput]['main'][0]

      }else if(data.lastInGroup) {
        if(data.fromGroupNextGroup?.groupInput){
          const newContext = state.outGroupContext.nodes[data.fromGroupNextGroup.groupId].grouped

          context = newContext
          condition = newContext.connections[data.fromGroupNextGroup.groupInput]['main'][0]
        }else{
          context = state.outGroupContext   
          condition = Object.values(context.connections[data.groupId])
              .find(item => item[0].endNodeId === data.nextNodeId)[0]
        }
      }else {
        condition = upperContext.connections[data.parentNodeId][data.nameConnect]
          .find(item => item.endNodeId === data.nextNodeId)
      }

      if(condition.connections == undefined || condition.connections.length == 0) {
        if(data.presetId === undefined){
          if(option === 'delete_presetId') return

          // без пресотов попадает сюда
          condition.connections = [
            {
              "conditions": {
                "condition-1": {}
              },
              "conditionExpression": [{
                "expressionPart": "expression-1",
                "p1": "condition-1"
              }]
            }
          ]
        }else{
          condition.connections = [{"presetId": data.presetId}]
        }
      }

      //  если массив conditions не пуст и существует
      let preset = condition.connections.find(item => item.presetId == data.presetId)

      // // добавить presetId
      if(option === 'presetId' && preset === undefined){
        Vue.set(condition.connections, condition.connections.length,  {"presetId": data.presetId})
      }

      if(option === 'condition'){
        // проверка длинны объекта
        if(preset.conditions !== undefined && Object.keys(preset.conditions)?.length > 0){
          //  Object.keys(preset.conditions).length > 0)

          if(Object.keys(preset.conditions).length === 0) {
            let conditionData = data.condition
            preset.conditions['condition-1'] = conditionData

          }else{

          // добавить в конец объекта входящие условия и пресет
            let length = Object.keys(preset.conditions).slice(-1)[0].split('-')[1]
            let nextId = ++Object.keys(preset.conditions).slice(-1)[0].split('-')[1]

            let nextNameCondition = `condition-${nextId}`
            let nameCondition = `condition-${length}`

            if(data.operator){
              let expressionPart = preset.conditionExpression.slice(-1)[0]
              let nameExpression = `expression-${nextId}`

              expressionPart.operation = data.operator
              expressionPart.p2 = nextNameCondition
              preset.conditions[nextNameCondition] = {}

              return preset.conditionExpression.push({
                "expressionPart": nameExpression,
                "p1": nextNameCondition
              })

            }else{
              let conditionName = data.index
                ? data.index
                : nameCondition

              preset.conditions[conditionName] = data.condition
              preset.conditions[conditionName].id = data.condition.id
            }
          }
        }else{
          // если массив условиями пуст
          preset.conditions = {"condition-1": {}}

          return preset.conditionExpression = [{
            "expressionPart": "expression-1",
            "p1": "condition-1"
          }]
        }

      }

      // change operators between conditions
      if(option === 'change_operator'){
        preset.conditionExpression[data.index].operation = data.newOperator
      }

      if(option === 'delete_presetId'){
        condition.connections = condition.connections.filter(item => item.presetId !== data.presetId)
      }

      if(option === 'delete_condition'){
        if(preset.conditionExpression.length == 1) {
          condition.connections = condition.connections.filter(item => item.presetId !== data.presetId)

        }else{
          let part1 = preset.conditionExpression.find(item => item.p1 === `condition-${data.conditionId}`),
              part2 = preset.conditionExpression.find(item => item.p2 === `condition-${data.conditionId}`),
              withOut1 = preset.conditionExpression.filter(item => item.p1 !== `condition-${data.conditionId}`)

          // for last element
          if(part1.p2 === undefined){
            preset.conditionExpression = withOut1
            delete part2.p2
            delete part2.operation

          }else{
            // for first element
            if(part2 === undefined) preset.conditionExpression = withOut1
            // for middle element
            else part2.p2 = part1.p2; preset.conditionExpression = withOut1
          }

          delete preset.conditions[`condition-${data.conditionId}`]
        }
      }

      if(option === 'cancel'){
        if(preset.conditionExpression.length > 1) {
          delete preset.conditionExpression[preset.conditionExpression.length - 2]['p2']
          delete preset.conditionExpression[preset.conditionExpression.length - 2]['operation']

          let cancel = Object.entries(preset.conditions)
          cancel.pop()
          preset.conditions = Object.fromEntries(cancel)
          return preset.conditionExpression.pop()
        }else{

          delete preset.conditionExpression
          return delete preset.conditions
        }
      }

      processesService.saveProcess(state.editorData, state.process.id)
    },
    setCanvasTheme(state, value) {
      state.editorData.options.canvasTheme = value;
    },
    setNameOfProcess(state, name){
      state.process.process_name = name
    },
    setGroupedContext(state, data){
      state.groupedContext = data
    },
    setOutGroupContext(state, data){
      state.outGroupContext = data
    },
    setParentNodeId(state, data){
      state.parentNodeId = data
    },
    setEnterNode(state, data){
      state.editorData.enterNode = data
    },
  },
  actions: {
    changeShowNav ({ commit }, value) {
      commit('changeShowNav', value)
    },
    setNewProcess ({commit}, value) {
      commit('setNewProcess', value);
    },
    changeIsEditNav ({ commit }, value) {
      commit('changeIsEditNav', value)
    },
    setOutsideProcessId({commit}, id) {
      commit("setOutsideProcessId", id);
    },
    changeReloadProcesses ({ commit }, value) {
      commit('changeReloadProcesses', value)
    },
    async getProcesses ({ commit }) {
      await processesService.getProcesses()
        .then(processes => {
          commit('getProcesses', processes.response.processes)
        })
        .catch(() => {
          commit('getProcesses', [])
        })
    },
    async getProcess ({ commit }, id) {
      if (id) {
        const response = await processesService.getProcess(id);
        if (response?.status === 1) {
          commit('getProcess', response.response);
        } else {
          commit('getProcess', null);
        }
      }
    },
    async deleteProcess ({ commit }, id) {
      await processesService.deleteProcess(id);
    },
    async saveProcess({state, getters}, project) {
      await processesService.saveProcess(project ? project : state.editorData, getters.process.id)
    },
    async updateNameProcess({commit, state, getters}, name) {
      commit('setNameOfProcess', name)
      await processesService.updateNameProcess(name, state.editorData, getters.process.id)
    },
    async addProcess ({ commit }, process_settings) {
      return await processesService.addProcess(process_settings);
    },
    setEditorData({commit}, editor_data) {
      commit('setEditorData', editor_data)
    },
    setConnectionsNodes({commit}, {data, option}) {
      commit('setConnectionsNodes', {data, option})
    },
    changeOutputName({commit, dispatch, state}, {nodeId, outputName, index}) {
      let context = state.localContext ?? state.editorData
      let node = context.nodes[nodeId]?.overwrittenNodeType?.outputNames

      if(node !== undefined) return commit('changeOutputName', {outputName, index, node})    
      dispatch('alert/error', 'Невозможно задать имя выхода', { root: true })
    },
    setLocalContext({commit}, data){
      commit('setLocalContext', data)
    },
    setCanvasTheme({ commit }, data) {
      commit('setCanvasTheme', data)
    },
    setGroupedContext({ commit }, data) {
      commit('setGroupedContext', data)
    },
    setOutGroupContext({ commit }, data) {
      commit('setOutGroupContext', data)
    },
    setParentNodeId({ commit }, data) {
      commit('setParentNodeId', data)
    },
    setEnterNode({commit}, data){
      commit('setEnterNode', data)
    }
  }
};
