<template>
   <v-dialog
     v-model="show"
     persistent
     fullscreen
     hide-overlay
     transition="dialog-bottom-transition"
     no-click-animation
   >
     <v-card class="d-flex flex-column node-edit__card">
       <v-toolbar
         :style="{'background': $colorAdmin}"
         elevation="0"
         class="flex-grow-0 justify-space-between"
       >
         <v-toolbar-title
           v-if="!nodeEditLoading"
           class="d-flex justify-space-between align-center"
         >
           <v-btn
               icon
               dark
               @click="close"
           >
             <v-icon class="light">mdi-close</v-icon>
           </v-btn>
           <span class="grey--text text--lighten-2 text-body-1">{{ $t('node.performanceNode') }}</span>
           <span class="ml-2">{{ settings.title }}<span class="text-caption"> | {{ settings.id }}</span></span>
         </v-toolbar-title>
         <div v-show="!nodeEditLoading">
           <v-select
               class="ml-3 mt-3 node-edit__select-lang"
               :items="languageItems"
               item-text="lang_name"
               item-value="lang_name"
               v-model="selectedLanguage"
               placeholder="node language"
               @change="setSettings"
           />
         </div>
       </v-toolbar>

       <SpinnerLoader
         v-if="nodeEditLoading"
         color="deep-purple accent-2"
       />
       <!-- настройка экрана -->
       <ScreenEdit v-else-if="screenId" />

       <!-- настройка нод -->
       <div v-else class="d-flex flex-grow-1">
         <!-- пресеты -->
         <v-col
           class="node-edit__nav px-6 py-4 align-start"
           ref="column1"
           id="node-edit__column1"
         >
           <h2 class="text-h5">{{ $t('node.presets') }}</h2>
           <GeneralNodeSettings @hideGeneral="isGeneral = $event" :enterNode="enterNode" />
         </v-col>
         <!-- left-resize -->
         <div
           class="node-edit__slider"
           @mousedown.stop="dragResize($event, 'column1', 'left')"
         >
           <div class="node-edit__slider__line"></div>
           <div
             v-if="isLeftExpand"
             class="node-edit__expand-arrow node-edit__expand-arrow_right"
             @click="isLeftExpand = false; expandBlock('column1')"
           >
             <v-icon>
               mdi-arrow-expand-right
             </v-icon>
           </div>
         </div>
         <!-- экраны -->
         <v-col class="node-edit__screens px-6 py-4">
           <div class="node-edit__content">
             <h2 class="text-h5">{{ $t('node.screens') }}</h2>
              <draggable v-model="settings.screens" v-if="settings.id !== 999">
               <transition-group name="screens" class="node-edit__views d-flex justify-center flex-wrap" tag="div">
                 <NodeScreen
                   v-for="setting in settings.screens"
                   :key="setting.name"
                   :canHide="setting.name.includes('hello') || setting.name.includes('anket')"
                   :screen-settings="settings.name.includes('anket')"
                   :setting="setting"
                   :active-actions="true"
                   @openScreenSettings="openScreenSettings"
                 />
               </transition-group>
             </draggable>
             <div v-else>
               <v-img width="150" height="280" contain src="@/assets/images/main-page.png" />
             </div>
           </div>
         </v-col>
         <!-- right-resize -->
         <div
           class="node-edit__slider"
           @mousedown.stop="dragResize($event, 'column3', 'right')"
         >
           <div class="node-edit__slider__line"></div>
           <div
             v-if="isRightExpand"
             class="node-edit__expand-arrow node-edit__expand-arrow_left"
             @click="isRightExpand = false; expandBlock('column3')"
           >
             <v-icon>
               mdi-arrow-expand-left
             </v-icon>
           </div>
         </div>
         <!-- условные переходы -->
         <v-col
           class="node-edit__nav px-6 py-4 align-start"
           ref="column3"
         >
           <div class="d-flex align-center mb-2">
            <h2 class="text-h5 mr-2 mb-0 font-weight-bold">{{ $t('node.conditions') }}</h2>
           </div>
           <div class="d-flex flex-column ml-3">
              <div v-for="(connection,i) in connectionsNode.arrayNodes" :key="connection.id" class="mb-4">
                  <NodePresets :connections="{
                      connection,
                      parentNodeId: node.id,
                      outputNames: localContext?.nodes[node.id]?.overwrittenNodeType?.outputNames,
                      lastInGroup: penultimateNodeInGroup,
                      fromGroupNextGroup: connection.fromGroupNextGroup?.firstNodeInGroup ? connection.fromGroupNextGroup : null,
                      hideSeparator: i === connectionsNode.arrayNodes.length-1,
                      unselectedPresets: connectionsNode.unselectedPresets,
                      preset,
                      node,
                      connections,
                    }"
                    @updateConditions="updateConditions"
                  />
                <div class="separator-line mt-8" :class="{'separator-line__hide' : i === connectionsNode.length-1}"></div>
              </div>
            </div>
         </v-col>
       </div>

       <!-- модалка -->
       <ConfirmModal ref="confirm" />
     </v-card>
   </v-dialog>
 </template>


<script>
import draggable from 'vuedraggable'
import NodeScreen from "@/components/nodes/NodeScreen";
import { EventBus } from "@/utils/event-bus";
import ConfirmModal from "@/components/utils/ConfirmModal";
import GeneralNodeSettings from "@/components/nodes/GeneralNodeSettings";
import ScreenEdit from "@/components/nodes/ScreenEdit";
import modalMixin from "@/mixins/modalMixin";
import popupPosition from "@/mixins/popupPosition";
import NodePresets from "@/components/nodes/NodePresets";

export default {
   name: 'NodeEdit',
   components: {
      ScreenEdit,
      GeneralNodeSettings,
      ConfirmModal,
      NodeScreen,
      draggable,
      NodePresets
   },
   props: {
      node: {
        required: true,
      },
      outGroupContext: {
        require: false
      },
      parentNodeId: {
        require: false
      },
      localContext: {
        required: true,
      },
      enterNode: {
        require: false
      }
   },
   mixins: [modalMixin, popupPosition],
   data() {
      return {
         isGeneral: true,
         editScreenId: false,
         screenDialog: false,
         isLeftExpand: false,
         isRightExpand: false,
         preset: localStorage.getItem(`preset-for-node-${this.node.db_id}`) ? Number(localStorage.getItem(`preset-for-node-${this.node.db_id}`)) : null,
         newItemPreset: {
            item: '',
            index: ''
         },
        selectedLanguage: 'en',
      }
   },
   computed: {
      settings() {
        return this.$store.getters["node/settings"]
      },
     languageItems() {
       return this.$store.getters["project/projectLangs"];
     },
      defaultSettings() {
        return this.$store.getters["node/defaultSettings"];
      },
      screenId() {
        return this.$store.getters["node/currentScreenId"];
      },
      // new
      availableTransitions() {
        return this.settings.availableTransitions;
      },
      outerConnections() {
        return this.$store.getters["node/outerConnections"];
      },
      nodeEditLoading() {
        return this.$store.getters["node/nodeEditLoading"];
      },
      nodesInfo() {
        return this.$store.getters["node/nodesInfo"];
      },
      /* eslint-disable */
      nodeId() {
        return this.$store.getters["node/nodeId"]
      },
      nameNode() {
         return this.settings.title ?? ''
      },
      connectionsNode:{
        cache: false,
        get: function () {
          let unselectedPresets = []
          let currentNodes = this.node
          let outConnections = this.localContext.connections[currentNodes.id] !== undefined
          let currentNode = outConnections
            ? Object.entries(this.localContext.connections[currentNodes.id])?.map((item, i) => {

              // for node with have next node group
              if(this.localContext.nodes[item[1][0].endNodeId].type === 'group'){

                let endNodeId = this.localContext.nodes[item[1][0].endNodeId] // группа
                let groupInput = Object.values(endNodeId.grouped.nodes).find(node => node.type === 'groupInput').id
                let firstGroupNode = Object.values(endNodeId.grouped.connections[groupInput])[0][0].endNodeId
                let output = Object.entries(this.localContext.connections[this.node.id]).find(node => node[1][0].endNodeId === endNodeId.id)[0] // у текущей ноды выход

                return {
                  endNode: endNodeId.grouped.nodes[firstGroupNode],
                  connects: endNodeId.grouped.connections[groupInput],
                  connectsToGroup: [output, this.localContext.connections[this.node.id][output]][1][0]?.connections,
                  connectName: output,
                  firstInGroup: endNodeId.id,
                  endNodeId: endNodeId.id,
                  groupInput,
                }

              }
              // for last node in group under exit
              if(this.localContext.nodes[item[1][0].endNodeId].type === 'groupOutput'){

                let endNodeId = this.localContext.nodes[item[1][0].endNodeId] // node groupOutput
                let parentNodeId = this.$store.getters['processes/parentNodeId'] // id node group
                let outputNodesOutGroup = []

                for (let item in this.outGroupContext.connections[parentNodeId]){
                  if(this.outGroupContext.nodes[this.outGroupContext.connections[parentNodeId][item][0].endNodeId].type === 'group'){

                    const intoGroup = this.outGroupContext.nodes[this.outGroupContext.connections[parentNodeId][item][0].endNodeId].grouped
                    const groupInput = Object.values(intoGroup.nodes).find(node => node.type === 'groupInput').id
                    const firstNodeInGroup = intoGroup.connections[groupInput]['main'][0].endNodeId
                    const groupId = this.outGroupContext.connections[parentNodeId][item][0].endNodeId

                    outputNodesOutGroup.push({firstNodeInGroup, groupInput, groupId})
                  }
                  else outputNodesOutGroup.push(this.outGroupContext.connections[parentNodeId][item][0].endNodeId)
                }
                return outputNodesOutGroup?.reduce((nodes, node) => {

                    let endNode = node?.firstNodeInGroup
                      ? this.outGroupContext.nodes[node.groupId].grouped.nodes[node.firstNodeInGroup]
                      : this.outGroupContext.nodes[node]

                    let connects = node?.firstNodeInGroup
                      ? this.outGroupContext.nodes[node.groupId].grouped.connections[node.groupInput]
                      : this.outGroupContext.connections[parentNodeId]

                    nodes.push({
                      endNode,
                      connects,
                      lastInGroup: endNodeId.id,
                      endNodeId: this.outGroupContext,
                      groupId: this.parentNodeId,
                      fromGroupNextGroup: node
                    })

                  return nodes
                }, [])

              }

              // for direct connection
              return {
                endNode: this.localContext.nodes[item[1][0].endNodeId],
                connects: this.localContext.connections[this.node.id],
              }

            })
            : null

          let arrayNodes = this.availableTransitions
          ?.reduce((arr, item, i) => {
            let nextNode = currentNode[0][0]?.groupId
              ? currentNode[0].find(nextNode => nextNode.endNode.db_id === item.idNextNode)
              : currentNode.find(nextNode => nextNode.endNode.db_id === item.idNextNode)

            let connectionsAll = Object.entries(nextNode.connects).find(item => {
              return item[1][0].endNodeId === nextNode.endNode.id
            })

            let connections = nextNode.connectsToGroup ?? connectionsAll[1][0]?.connections
            let connectionsData = {endNodeId: nextNode.endNode.id, node: nextNode.endNode, connectName: nextNode.connectName ?? connectionsAll[0], groupInput: nextNode.groupInput, firstInGroup: nextNode.firstInGroup, lastInGroup: nextNode.lastInGroup, groupId: nextNode.groupId, fromGroupNextGroup: nextNode.fromGroupNextGroup, groupId: nextNode.groupId }

            // If node have presets
            if(item.presets.length > 0){
              let presets = item.presets?.reduce((arr, preset, presetIndex) => {

                const first = arr.some(itemPreset => itemPreset.endNodeId === connectionsData.endNodeId)
                const lengthPresets = item.presets.length - 1
                const last = presetIndex === lengthPresets
                const allPresets = Object.values(arr).length === lengthPresets
                preset.id_node = item.idNextNode

                // If presets have rules
                if(connections !== undefined && connections.find(item => item.presetId === preset.id) !== undefined){
                  arr.push({ ...connectionsData, connect: connections.find(item => item.presetId === preset.id ), first, allPresets })
                }else{
                 const connections = nextNode?.connects?.connections
                    ? nextNode?.connects?.connections[0]
                    : null

                  if(!first && last) arr.push({ ...connectionsData, connect: {presetId: preset.id, option: 'presetId', ...connections }, first, allPresets })
                  // mark unselected presets
                  else unselectedPresets.push(preset)
                }
                return arr
              },[])
              arr.push(...presets)
            // If node have't presets
            }else{
              if(connections !== undefined && connections.length > 0 ) arr.push({ ...connectionsData, connect: connections[0], first: false, allPresets: true })
              else arr.push({ ...connectionsData, connect: {}, first: false, allPresets: true })
            }

            // check for all presets
            const isAllPresets = arr.filter(node => node.endNodeId === nextNode.endNode.id).some( node => node.allPresets)
            if(isAllPresets) arr?.map(node => {
              return node.endNodeId === nextNode.endNode.id
                ? node.allPresets = true
                : node
            })

            return arr
          }, [])

          if(outConnections){
            // sort getting array for order
            let connectionsDbId = Object
              .values(this.localContext.connections[currentNodes.id])?.map(connection =>  connection[0].endNodeId)

            let sortable = connectionsDbId?.reduce((arr, connectId, connectIndex) => {
              arr[connectId] = connectIndex
              return arr
            }, {})

            let sortableNodes = arrayNodes?.sort((a, b) => sortable[a.endNodeId] - sortable[b.endNodeId])

            let onlyId = [...new Set(sortableNodes?.map(item => item.endNodeId))]

            let onlyIdFiltered = onlyId?.reduce((arr, id, idIndex) => {
              sortableNodes.forEach(node => {
                if(node.endNodeId === id) arr.push({...node, index: idIndex})
                else return
              })
              return arr
            }, [])
            return{ arrayNodes: onlyIdFiltered,  unselectedPresets }
          }
          return { arrayNodes, unselectedPresets }
        }
      },
      connections() {
        let connections
          if(this.outerConnections.length > 0) {
            connections = this.outerConnections.filter(id => !!id).join(',')
          }else{
            connections = null
          }

        this.$store.dispatch('node/setConnections', connections)
        return connections
      },
      penultimateNodeInGroup(){
        const idGroupOutput = Object.values(this.localContext.connections[this.node.id])[0][0].endNodeId
        if(this.localContext.nodes[idGroupOutput].type === "groupOutput") return this.localContext.nodes[idGroupOutput].id
      }
    },
    // set node
    async created() {
      this.selectedLanguage = sessionStorage.getItem(`node-${this.node.db_id}-${this.preset}-lang`) || 'en'
      await this.setSettings();
   },
  methods: {
      /*
       * expand block to max block width
       * */
      expandBlock(blockRef) {
        let block = this.$refs[blockRef];
        if (blockRef === 'column1') {
          block.style.width = window.screen.width * 0.25 + 'px'
          block.style.display = 'flex'
          return block.style['flex-direction'] = 'column'
        }
        block.style.width = window.screen.width * 0.25 + 'px'
        block.style.display = 'block'
      },
      /*
       * change width of node edit blocks while dragging slider
       * */
      dragResize: function (e, blockRef, site) {
         let dragX = e.clientX;
         let block = this.$refs[blockRef];
         const self = this;
         let minMove = window.screen.width * 0.03

         document.onmousemove = function onMouseMove(e) {
            if (site === 'left') {
               if ((block.offsetWidth - dragX) > minMove) {
                  block.style.display = 'none';
                  self.isLeftExpand = true;
               }
               block.style.width = block.offsetWidth + e.clientX - dragX + "px";
               if (block.offsetWidth < 10) {
                  self.isLeftExpand = true;
               } else {
                  self.isLeftExpand = false;
               }
            } else {
               if ((block.offsetWidth - (window.innerWidth - dragX)) > minMove) {
                  block.style.display = 'none';
                  self.isRightExpand = true;
               }

               block.style.width = block.offsetWidth + (window.innerWidth - e.clientX) - (window.innerWidth - dragX) + 'px';
               if (block.offsetWidth < 10) {
                  self.isRightExpand = true;
               } else {
                  self.isRightExpand = false;
               }
            }
            dragX = e.clientX;
         }

         document.onmouseup = () => document.onmousemove = document.onmouseup = null;
      },

      /*
       * reset node settings
       * */
      async resetSettings() {
        if (await this.$refs.confirm.open(
            "Сброс настроек ноды",
            "Вы уверены, что хотите поставить дефолтные настройки ноды?"
          )) {
          await this.$store.dispatch('node/setSettings', {
            id: null,
            preset: null,
            type: null
          });
          // emit global reset settings with bus
          EventBus.$emit('nodeGlobalReset');
        }
      },
      async setSettings() {
        this.$store.commit('node/setNodeEditLoading', true);
        await this.$store.dispatch("node/setSettings", {
          id: this.node.db_id,
          preset: this.preset,
          type: this.node.type,
          connections: this.connections,
          lang: this.selectedLanguage
        })
        this.$store.commit('node/setNodeEditLoading', false);
      },
      // save node settings
      async saveNode() {
        await this.$store.dispatch('node/saveNode', this.settings);
      },
      // open touched screen settings
      openScreenSettings(screenId) {
        this.editScreenId = screenId;
      },
      // close settings modal
      close() {
        this.show = false;
        this.$store.dispatch('node/setCurrentField', null);
        this.$store.dispatch('node/setCurrentScreenId', null);
      },
      updateConditions(){
        this.$forceUpdate()
      }
   }
}
</script>

<style scoped lang="scss">
.node-edit {
  &__select-lang {
    max-width: 60px;
  }
    &__card {
      background-color: #f8f9fa !important;
    }

    &__nav {
      position: relative;
      background-color: #ffffff;
      box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
    }

    &__views {
      row-gap: 0px;
      column-gap: 60px;
      .phone-screen__wrapper{
        margin-bottom: -5em;
      }
    }

    &__slider {
      position: relative;
      width: 1px;
      border: none;
      cursor: col-resize;
      user-select: none;
      text-align: center;

      &__line {
        width: 1px;
      }
    }

    &__expand-arrow {
      transition: .5s all ease;
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 50%;

      &_right {
        right: -30px;
      }

      &_left {
        left: -30px;
      }
    }

    &__portal-target {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(#212121, 0.4);
      top: 0;
      left: 0;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: start;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // ?
    &__screens {

      &-move,
      &-enter-active,
      &-leave-active {
        transition: all 0.5s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }

      &-leave-active {
        position: absolute;
      }
    }
  }
.separator-line{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(140,161,203,1) 50%, rgba(255,255,255,1) 100%);
  width: 100%;
  height: 1px;
  &__hide{
    display: none;
  }
}
</style>
